/* global site */

// Import site modules
// import ('./site/cookie-policy.js');
import ('./site/scroll-to.js');

window.site = (window.site || {});

/**
 * Main application class.
 * @class App
 * @static
 */
window.site.App = (function App() {
  /**
   * Has the class been initialized?
   * @private
   */
  let inited = false;

  /**
   * Application config defaults.
   * @private
   * @param config.env     Current server environment
   * @param config.csrf    Security token to submit with forms
   * @param config.csrfName    Security token to submit with forms
   * @param config.locale  Current locale short code
   * @param config.device  Device detection based on browser signature
   * @param config.preview Page is shown through live preview mode
   * @param config.general Settings from general config
   */
  const config = {
    env: 'production',
    csrf: null,
    csrfName: null,
    locale: 'en',
    device: 'desktop',
    preview: false,
    general: {},
  };

  /**
   * Initializes the class.
   * @public
   */
  const init = function init(options) {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Store application settings
    options = (options || {});

    if (options.env) { config.env = options.env; }
    if (options.csrf) { config.csrf = options.csrf; }
    if (options.csrfName) { config.csrfName = options.csrfName; }
    if (options.locale) { config.locale = options.locale; }
    if (options.device) { config.device = options.device; }
    if (options.preview) { config.preview = options.preview; }
    if (options.general) { config.general = options.general; }

    // Global
    // Detect css-grid un-supported
    const el = document.createElement("div")
    const supportsGrid = "string" == typeof el.style.grid;

    if(!supportsGrid) {
      const body = document.getElementsByTagName("body")[0].classList.add('is-ie-11');
    } else {
      // IE 11 || Old Browser - Ignore zone
      console.table(options);

      // Register ServiceWorker
      if ('serviceWorker' in navigator) {
        console.log('Registering ServiceWorker...');
        window.addEventListener('load', function() {
            navigator.serviceWorker.register('/sw.js', {
                scope: "/"
            }).then(function(registration) {
                // Registration was successful
                console.log('ServiceWorker registration successful with scope: ', registration.scope);
                // Trim the caches on load
                navigator.serviceWorker.controller && navigator.serviceWorker.controller.postMessage({
                    command: "trimCaches"
                });
           }).catch(function(err) {
                // registration failed :(
                console.log('ServiceWorker registration failed: ', err);
            });
        });
      }

      // PreRender for fast website
      function prerenderlink(e) {
        console.log(e, e.currentTarget, e.currentTarget.href);
        var head = document.getElementsByTagName("head")[0];
        var refs = head.childNodes;
        var ref = refs[ refs.length - 1];

        var elements = head.getElementsByTagName("link");
        Array.prototype.forEach.call(elements, function(el, i) {
          if (("rel" in el) && (el.rel === "prerender"))
            el.parentNode.removeChild(el);
        });

        var prerenderTag = document.createElement("link");
        prerenderTag.rel = "prerender";
        prerenderTag.href = e.currentTarget.href;
        ref.parentNode.insertBefore(prerenderTag,  ref);
      }

      const $prerenderLinks = document.querySelectorAll('.main-nav a');
      [].slice.call($prerenderLinks).forEach(function(elem) {
        elem.addEventListener('mouseenter', prerenderlink);
      });
    }

    // Aside Form
    const formTriggers = [].slice.call(document.querySelectorAll('[data-trigger-aside-form]'));

    formTriggers.forEach(function(item) {
      item.addEventListener('click', function(e) {
        e.preventDefault();
        document.querySelector('body').classList.toggle('aside-form-active');
      });
    });

    // Mobile Menu
    document.querySelector('.mobile-menu-trigger').addEventListener('click', function(e) {
      e.preventDefault();
      document.querySelector('.navigation-mobile').classList.add('is-active');
      document.querySelector('.navigation-mobile-overlay').classList.add('is-active');
    });

    document.querySelector('.navigation-close').addEventListener('click', function(e) {
      e.preventDefault();
      document.querySelector('.navigation-mobile').classList.remove('is-active');
      document.querySelector('.navigation-mobile-overlay').classList.remove('is-active');
    });

    document.querySelector('.navigation-mobile-overlay').addEventListener('click', function(e) {
      e.preventDefault();
      document.querySelector('.navigation-mobile').classList.remove('is-active');
      document.querySelector('.navigation-mobile-overlay').classList.remove('is-active');
    });
    
    
    
    var last_known_scroll_position = 0;
    var ticking = false;
    var check = document.querySelector('#page-header').offsetHeight;

    function checkScrollPos(scroll_pos) {
      if (scroll_pos >= check) {
        document.querySelector('#page-header').classList.add('has-fixed');
        document.querySelector('.main-nav').classList.add('is-fixed');
        document.querySelector('body').classList.add('has-fixed');
      } else {
        document.querySelector('#page-header').classList.remove('has-fixed');
        document.querySelector('.main-nav').classList.remove('is-fixed');
        document.querySelector('body').classList.remove('has-fixed');
      }
    }
      
    document.addEventListener('scroll', function(e) {
      last_known_scroll_position = window.scrollY;

      if (!ticking) {
        window.requestAnimationFrame(function() {
          checkScrollPos(last_known_scroll_position);
          ticking = false;
        });

        ticking = true;
      }
    });
      
      

    // Return success
    return true;
  };

  /**
   * Getter for application config.
   * @public
   */
  const getConfig = function getConfig(option) {
    return config[option] ? config[option] : false;
  };

  /**
   * Expose public methods & properties.
   */
  return {
    init,
    config: getConfig,
  };
}());
